import styled from '@emotion/styled';
import type { FC, ReactNode } from 'react';
import { Font } from '@pafcloud/style';
import { useTranslation } from '@pafcloud/i18n';
import { Icon } from '../icon';
import { BadgeColors } from './badge-colors';

const DefaultBadgeSize = 16;

type BadgeWrapperProps = {
  size: number | string;
  isInRightCorner: boolean;
};

const BadgeWrapper = styled.figure<BadgeWrapperProps>(({ size, isInRightCorner }) => ({
  '--badge-background-color': isInRightCorner ? BadgeColors.RightCornerBackground : BadgeColors.Background,
  position: 'relative',
  zIndex: 1,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  margin: 0,
  pointerEvents: 'none',
  boxShadow: BadgeColors.BadgeShadow,
  color: isInRightCorner ? BadgeColors.RightCornerIconAndTextColor : BadgeColors.IconAndTextColor,

  width: size,
  height: size,
  borderRadius: '50%',
  lineHeight: 0,
  background: 'var(--badge-background-color)',
}));

const Text = styled.span<Pick<NewBadgeProps, 'size'>>(({ size = DefaultBadgeSize }) => ({
  position: 'absolute',
  top: 0,
  right: 0,
  left: 0,
  bottom: 0,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  fontFamily: Font.Primary,
  fontWeight: 'bold',
  textTransform: 'uppercase',
  letterSpacing: '-0.02em',
  fontSize: size / 2,
  transform: 'scale(0.55)',
  whiteSpace: 'nowrap',
  textShadow:
    '-2px -2px 0 var(--badge-background-color), 2px -2px 0 var(--badge-background-color), -2px 2px 0 var(--badge-background-color), 2px 2px 0 var(--badge-background-color)',
}));

const BadgeIcon = styled(Icon)({
  position: 'absolute',
  top: 0,
  right: 0,
  left: 0,
  bottom: 0,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
});

type BadgeProps = {
  size?: number | string;
  bonusProductUnits?: boolean;
  className?: string;
  children?: ReactNode;
};

type NewBadgeProps = {
  size?: number;
  className?: string;
  children?: ReactNode;
};

const defaultIconSize = '65%';

export const Badge: FC<BadgeProps> = ({ size = DefaultBadgeSize, bonusProductUnits, className, children }) => {
  return (
    <BadgeWrapper className={className} size={size} isInRightCorner={bonusProductUnits ?? false} data-testid="badge">
      {children}
    </BadgeWrapper>
  );
};

export const NewBadge: FC<NewBadgeProps> = ({ className, size }) => {
  const { t } = useTranslation('common');

  return (
    <Badge className={className} size={size}>
      <Text size={size}>{t('new')}</Text>
    </Badge>
  );
};

export const BonusBadge: FC<BadgeProps> = ({ className, size }) => {
  return (
    <Badge className={className} size={size}>
      <BadgeIcon name="gift" size={defaultIconSize} data-testid="bonus-icon" />
    </Badge>
  );
};

export const MaintenanceBadge: FC<NewBadgeProps> = ({ className, size }) => {
  return (
    <Badge className={className} size={size}>
      <BadgeIcon name="maintenance" size={defaultIconSize} data-testid="maintenance-icon" />
    </Badge>
  );
};

// Freespins or bingo tickets
type BonusProductUnitsBadgeProps = NewBadgeProps & { productUnits: number };

export const BonusProductUnitsBadge: FC<BonusProductUnitsBadgeProps> = ({ className, size, productUnits }) => {
  return (
    <Badge className={className} size={size} bonusProductUnits>
      <Text size={size}>{productUnits}</Text>
    </Badge>
  );
};
