import { $buildEnv } from '@pafcloud/config/src/buildEnv';
import { Colors as Colors_default } from './colors.default';
import { Colors as Colors_classic_red } from './colors.classic-red';
import { Colors as Colors_clean } from './colors.clean';
import { Colors as Colors_dreams } from './colors.dreams';
import { Colors as Colors_momentum } from './colors.momentum';
import { Colors as Colors_momentum_neon } from './colors.momentum-neon';

export const BadgeColors = (() => {
  if ($buildEnv.theme === 'classic-red') {
    return Colors_classic_red;
  }
  if ($buildEnv.theme === 'clean') {
    return Colors_clean;
  }
  if ($buildEnv.theme === 'clean-blue') {
    return Colors_clean;
  }
  if ($buildEnv.theme === 'dreams') {
    return Colors_dreams;
  }
  if ($buildEnv.theme === 'momentum') {
    return Colors_momentum;
  }
  if ($buildEnv.theme === 'momentum-neon') {
    return Colors_momentum_neon;
  }

  return Colors_default;
})();
