import { Color } from '@pafcloud/style';

export const Colors = {
  Background: Color.Primitive.Secondary,
  IconAndTextColor: Color.Primitive.SecondaryContrast,

  RightCornerBackground: Color.Surface.Base.Background,
  RightCornerIconAndTextColor: Color.Surface.Base.Foreground,

  BadgeShadow: Color.Elevation.Level2,
};
